import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import {
    CheckboxVisibility,
    DetailsListLayoutMode,
    IColumn,
    Selection,
    SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import { ShimmeredDetailsList, Stack } from "office-ui-fabric-react";
import { listContainerS, listS, footer } from "../../clients/components/clientsList.styles";
import { IRenderProps } from "../../orders/providers/Pagination.types";
import { sort, toggleFiltration } from "../../../config/redux/filters/filterActions";
import Pagination from "../providers/Pagination";
import { containersColumns } from "../columns/containersColumns";
import { IContainerListState, IDispatchProps, IProps, IStateProps } from "../models/ContainersList.types";
import { setContainers } from "../../../config/redux/container/containersActions";
import moment from "moment";

class ContainersList extends React.Component<IProps, IContainerListState> {
    private _selection: Selection;
    private _columns: IColumn[];
    private _generatedColumns: IColumn[];
    private _onItemInvoked: (item?: any, index?: number, e?: Event) => void;
    private _onColumnHeaderClick: (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => void;
    protected _footerRef: React.RefObject<HTMLDivElement>;
    constructor(props: IProps) {
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => {
                const items = this._getSelectedKeys();
                const ids = items.map((container: any) => {
                    return {
                        id: container.id,
                        key: container.id,
                        closestCompanyName: container.closestCompanyName,
                        closestCompanyTaxNumber: container.closestCompanyTaxNumber,
                        closestReceptionPlaceAddress: container.closestReceptionPlaceAddress,
                        closestReceptionPlaceType: container.closestReceptionPlaceType,
                        closestReceptionPlaceDistance: container.closestReceptionPlaceDistance,
                        iccid: container.iccid,
                        imei: container.imei,
                        lastActiveDate: moment(container.lastActiveDate).format('DD.MM.YYYY HH:mm:ss'),
                        lastActiveDateStr: container.lastActiveDateStr,
                        latitude: container.latitude,
                        longitude: container.longitude,
                        serNo: container.serNo,
                        status: container.status,
                        statusString: container.statusString,
                        containerNumber: container.containerNumber,
                        // identifier: container.identifier,
                        currentBatteryLevel: container.currentBatteryLevel,
                        description: container.description,
                        // uhfIdentifier: container.uhfIdentifier,
                        hasPhotos: container.hasPhotos,
                        internalStoreName: container.internalStoreName,
                        currentBatteryStatus: container.currentBatteryStatus,
                        currentBatteryStatusString: container.currentBatteryStatusString
                    };

                });
                const data = [...ids];
                this.props.dispatch(setContainers(data));
                //props.dispatch(toggleReceptionPlacesPanel({isOpen: true}));
            },
        });

        this._footerRef = React.createRef();

        this._generatedColumns = containersColumns;

        this._onItemInvoked = (item: any) => {
            // this.props.dispatch(setActiveClient({ activeClient: item }));
            //props.dispatch(toggleReceptionPlacesPanel({isOpen: true}));
        };

        this._columns = [...this._generatedColumns];

        this._onColumnHeaderClick = (item: any, column: any) => {

            const newColumns: IColumn[] = this._columns.slice();

            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
            newColumns.forEach((newCol: IColumn) => {
                if (newCol === currColumn) {
                    currColumn.isSortedDescending = !currColumn.isSortedDescending;
                    currColumn.isSorted = true;
                } else {
                    newCol.isSorted = false;
                    newCol.isSortedDescending = true;
                }
            });

            props.dispatch(toggleFiltration({ filter: true }));
            props.dispatch(sort({ column: currColumn }));

            this.setState({
                _cols: newColumns,
            });
        };

        this.state = {
            items: [],
            loading: false,
            _cols: this._columns
        };
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(setContainers([]));
    }

    componentDidUpdate() {
        if (this.props.reload) {
            this._selection.selectToKey("", true);
        }

        if (!this.props.selectedContainers[0]) {
            this._selection.selectToKey("", true);
        }
    }


    public render(): JSX.Element {
        return (
            <>
                <Pagination
                    footerRef={this._footerRef}
                    render={({ data, loading }: IRenderProps) => (
                        <>
                            <Stack styles={listContainerS}>
                                <MarqueeSelection isEnabled={!this.props.isPanelOpen} selection={this._selection}>
                                    <ShimmeredDetailsList
                                        items={data}
                                        columns={containersColumns}
                                        setKey="items"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        selection={this._selection}
                                        selectionPreservedOnEmptyClick={true}
                                        onItemInvoked={this._onItemInvoked}
                                        usePageCache={true}
                                        styles={listS}
                                        onColumnHeaderClick={this._onColumnHeaderClick}
                                        enableShimmer={loading}
                                        checkboxVisibility={CheckboxVisibility.onHover}
                                        selectionMode={SelectionMode.single}
                                    />
                                </MarqueeSelection>
                            </Stack>
                            <div className={footer} ref={this._footerRef} />
                        </>
                    )}
                />
            </>
        );
    }

    private _getSelectedKeys = () => {
        const keys = this._selection.getSelection();
        return keys;
    };
}

const mapStateToProps = (state: RootStateOrAny) => state.containers;

export default connect<IStateProps, IDispatchProps>(
    mapStateToProps
)(ContainersList);