export const containersColumns = [
    {
        key: "containerNumber",
        name: "Numer kontenera",
        fieldName: 'containerNumber',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    // {
    //     key: "identifier",
    //     name: "Identyfikator / QR",
    //     fieldName: 'identifier',
    //     minWidth: 50,
    //     maxWidth: 100,
    //     isResizable: true,
    // },
    // {
    //     key: "uhfIdentifier",
    //     name: "Kod UHF bramki",
    //     fieldName: 'uhfIdentifier',
    //     minWidth: 50,
    //     maxWidth: 100,
    //     isResizable: true,
    // },
    {
        key: "closestReceptionPlaceAddress",
        name: "Przybliżona lokalizacja",
        fieldName: 'closestReceptionPlaceAddress',
        minWidth: 100,
        maxWidth: 250,
        isResizable: true,
    },
    // {
    //     key: "imei",
    //     name: "Numer IMEI",
    //     fieldName: 'imei',
    //     minWidth: 100,
    //     maxWidth: 200,
    //     isResizable: true,
    // },
    {
        key: "serNo",
        name: "Numer seryjny",
        fieldName: 'serNo',
        minWidth: 75,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "lastActiveDate",
        name: "Ostatnia aktywność",
        fieldName: 'lastActiveDateStr',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: "internalStoreName",
        name: "Magazyn",
        fieldName: 'internalStoreName',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
    },
    {
        key: "status",
        name: "Status",
        fieldName: 'statusString',
        minWidth: 100,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: "currentBatteryStatusString",
        name: "Konserwacja baterii",
        fieldName: 'currentBatteryStatusString',
        minWidth: 100,
        maxWidth: 250,
        isResizable: true,
    },


    // {
    //     key: "currentBatteryLevel",
    //     name: "Bateria [V]",
    //     fieldName: 'currentBatteryLevel',
    //     minWidth: 50,
    //     maxWidth: 100,
    //     isResizable: true,
    // },
    // {
    //     key: "hasPhotos",
    //     name: "Zdjęcie",
    //     fieldName: 'hasPhotos',
    //     minWidth: 40,
    //     maxWidth: 60,
    //     isResizable: true,
    // },
]