import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { checkQuantity } from "../../actions/checkQuantity";
import { generateItems } from "../../actions/generateCommands/generateNotConfirmedUsersCommands";
import { reloadNotConfirmedUser, toggleNotConfirmedUserPanel } from "../../../../config/redux/notConfirmedUsers/notConfirmedUsersActions";
import { confirmNotConfrmedUser } from "../../../../connectors/notConfirmedUsers/connectors";
import { setErrorAlert, setListMessage } from "../../../../config/redux/alerts/alertsActions";

enum Roles {
  ADMIN = 1,
  DRIVER = 3
}

export const useNotConfirmedUsersCommandBar = () => {

  const dispatch = useDispatch();
  const { selectedNotConfirmedUsers } = useSelector(
    (state: RootStateOrAny) => state.notConfirmedUsers
  );

  const openPanelToConfirm = () => {
    dispatch(toggleNotConfirmedUserPanel({ open: true }))
  }

  const confirmAsAdmin = async () => {
    try {
      var user = selectedNotConfirmedUsers[0];
      await confirmNotConfrmedUser(user.id, {
        userId: user.Id,
        roleId: Roles.ADMIN
      })
      dispatch(reloadNotConfirmedUser({ reload: true }))
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    }
    catch (err) {
      dispatch(setErrorAlert("Wystąpił błąd"))
    }
  }

  const confirmAsDriver = async () => {
    try {
      var user = selectedNotConfirmedUsers[0];
      await confirmNotConfrmedUser(user.id, {
        userId: user.Id,
        roleId: Roles.DRIVER
      })
      dispatch(reloadNotConfirmedUser({ reload: true }))
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    }
    catch (err) {
      dispatch(setErrorAlert("Wystąpił błąd"))
    }
  }

  const actions = {
    openPanelToConfirm,
    confirmAsAdmin,
    confirmAsDriver
  };

  const quantity = checkQuantity(selectedNotConfirmedUsers.length);

  const items = generateItems(quantity, actions);

  return {
    items,
  };
}