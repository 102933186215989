import { IQuantity, Quantity } from "../../../common/models/quantity.model";

interface IActions {
  openPanelToConfirm: () => void;
  confirmAsAdmin: () => void;
  confirmAsDriver: () => void;
}

export const generateItems = (quantity: IQuantity, actions: IActions) => {
  switch (quantity) {
    case Quantity.ONE:
      return [
        // {
        //   key: "edit",
        //   text: "Edytuj",
        //   iconProps: { iconName: "edit" },
        //   name: "edit",
        //   onClick: actions.openPanelToConfirm,
        // },
        {
          key: "confirm",
          text: "Zatwierdź",
          iconProps: { iconName: "edit" },
          name: "edit",
          onClick: actions.openPanelToConfirm,
          subMenuProps: {
            items: [{
              key: "confirmAsAdmin",
              text: "Zatwierdź jako Admin",
              iconProps: { iconName: "edit" },
              name: "edit",
              onClick: actions.confirmAsAdmin,
            },
            {
              key: "confirmAsDriver",
              text: "Zatwierdź jako Kierowca",
              iconProps: { iconName: "edit" },
              name: "edit",
              onClick: actions.confirmAsDriver,
            }
            ],
          }
        }
      ];
    default:
      return [];
  }
};